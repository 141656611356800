import styled from 'styled-components/macro'

export const Track = ( props, state ) => {

  const fill = "#C7AD99"
  
  return (
    <StyledTrack 
      {...props} 
      fill={fill}
      index={state.index}
      key={props.key} />      
  )

}

const StyledTrack = styled.div`
  /* background: ${({ index }) =>
    index === 2 ? '#f00' : index === 1 ? '#0f0' : '#ddd'}; */
  /* background: #F3F3F3; */
  background: ${({ fill }) => fill};
  border-radius: 29px;
  height: 4px;
  top: 50%;

  
  transform: translatey(-50%);
`