import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function Price({ label, value }) {
  return (
    <Box>
      <div className="paragraph1-light">{label}</div>
      <Box className="h3"  mt={0.75}>
        {value}
      </Box>
    </Box>
  );
}

Price.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Price;
