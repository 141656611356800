import { Box, Divider, Stack, useMediaQuery } from "@mui/material";
import { ButtonCompare, ButtonFavorite, ButtonShare } from "components/Unit";
import { useGet } from "state/jotai";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NotScrollParent, ScrollParent, SwiperBox } from "./Compare.style";
import { ImgWrapper } from "components/ImgContainer/ImgWrapper";
import { FLOORPLAN }      from 'api/api'
import { params_query } from "state/store.global";
import { useBreak } from "hooks/useBreak";

const CompareCards = ({ units, createGroupArr, x, buttonsLabel, handleScroll, cardRef, passRef, isScrolled }) => {
    const isMobile  = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const isDesktop = useBreak('md_up')
    const params    = useGet(params_query);

    return (
      <>
       <Box
        sx={{
          display      : "flex",
          flexDirection: "column",
          padding      : !isDesktop ? '0 0 0 16px': '0 0 0 48px',
          position     : 'relative',
          background   : "#fff",
          zIndex       : '1',
        }}
      >
        {!units?.length ? (
          <Box sx = {{ textAlign: "center", mt: 5 }}>
            There is no available unit to compare
          </Box>
        ) : (
          <>
          <Box
            sx={{
              width: isScrolled ? "100%": "auto"
            }}
          >
            <Swiper {...SwiperProps} style={{ height: 'auto' }} ref={passRef} onSliderMove={(e)=> {
               handleScroll(e, 'swiper')
            }}>
              {units.map((i, j) => {
                const status     = i.Data.UnitStatusName.UnformattedValue;
                const floorplan  = i.Gallery?.Floorplan?.Url || FLOORPLAN;
                const idx        = j < i.Gallery?.ListOfPhotos.length ? j : 0;
                const coverImage = floorplan
                  ? floorplan
                  :   i.Gallery?.ListOfPhotos[idx]?.Url || "";  // use random image from gallery if floorplan is missing
                const address   = i.Data.Adresse.Value;

                const availablePris = i?.Data?.Pris_Tekst_DK?.Value === "";
                const isRent = i.Data.SalesTypeLejelejlighed.Value
                const prisValue = isRent ? i.Data?.Husleje?.Value : i.Data?.Kontantpris?.Value;
                const prisPost  = isRent ? i.Data?.Husleje?.Postfix + " " + i.Data?.LejelejlighedsText?.Value : " " +  i.Data?.Kontantpris?.Postfix;
         
                const pris = prisValue
                const pricePf = (availablePris && pris) ? prisPost : "" 

                const groupArr  = createGroupArr({ i, comparePropertyNames: x });
                const ColorCode = i.Data.UnitColor.Value;
                const projeknr  = `${
                  buttonsLabel?.filter(
                    (btn) => btn?.UILabelProperty === "Bolignr"
                  )[0]?.Label
                } ${i.Data?.Projeknr?.Value}`;
                const townAndZipcode = 
                  i?.Data?.ZipcodeID?.Value + " " + i?.Data?.By?.Value;
              
                const props = { status, floorplan, projeknr, address, townAndZipcode, pris, pricePf, i, isMobile: !isDesktop , ColorCode }
  
                return (
                  <SwiperSlide
                    style = {{ height: "fit-content", paddingBottom: 24, width: !isDesktop ? '250px' :'450px' }}
                    key   = {j}
                  >
                    <SwiperBox
                      style={{
                        backgroundColor: `${ColorCode}22`,
                        minHeight         : !isDesktop ? '331px': '449px',
                        width          : !isDesktop ? '250px': '450px',
                        borderRadius   : '6px'
                      }}
                    >
                        <NotScrollParent>
                          <NotScrolledCard { ...props } />
                        </NotScrollParent>                 
                    </SwiperBox>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>

          { isScrolled &&
            <div
              style={{
                position  : "fixed",
                top       : !isDesktop ? "169px": "199px",
                left      : !isDesktop ? "16px" : "48px",
                width     : "calc(100% - 16px)",
                zIndex    : 5,
                background: 'white',
                transition: 'top 5s linear'
              }}
            >
              <Swiper {...SwiperProps} style = {{ height: 'auto' }}
                      ref                    = {cardRef}
                      onSliderMove           = {(e)=> {
                handleScroll(e, 'swiper')
              }}
              >
                {units.map((i, j) => {
                  const salesType  = i.Data.SalesTypeName.Value;
                  const status     = i.Data.UnitStatusName.UnformattedValue;
                  const floorplan  = i.Gallery?.Floorplan?.Url || FLOORPLAN;
                  const idx        = j < i.Gallery?.ListOfPhotos.length ? j : 0;
                  const coverImage = floorplan
                    ? floorplan
                    :   i.Gallery?.ListOfPhotos[idx]?.Url || "";  // use random image from gallery if floorplan is missing
                  const address   = i.Data.Adresse.Value;
              
                  
                  const availablePris = i?.Data?.Pris_Tekst_DK?.Value === "";
                  const isRent = i.Data.SalesTypeLejelejlighed.Value
                  const prisValue = isRent ? i.Data?.Husleje?.Value : i.Data?.Kontantpris?.Value;
                  const prisPost  = isRent ? i.Data?.Husleje?.Postfix + " " + i.Data?.LejelejlighedsText?.Value : " " +  i.Data?.Kontantpris?.Postfix;
           
                  const pris = prisValue
                  const pricePf = (availablePris && pris) ? prisPost : "" 

                  
                  const groupArr  = createGroupArr({ i, comparePropertyNames: x });
                  const ColorCode = i.Data.UnitColor.Value;
                  const projeknr  = `${
                    buttonsLabel?.filter(
                      (btn) => btn?.UILabelProperty === "Bolignr"
                    )[0]?.Label
                  } ${i.Data?.Projeknr?.Value}`;
                  const townAndZipcode = 
                    i?.Data?.ZipcodeID?.Value + " " + i?.Data?.By?.Value;

                  const props = { status, floorplan, projeknr, address, townAndZipcode, pris, pricePf, i, isMobile: !isDesktop , ColorCode }

                  return (
                    <SwiperSlide
                      style = {{ height: "fit-content", paddingBottom: 24, width: !isDesktop ? '250px' :'450px' }}
                      key   = {j}
                    >
                      <SwiperBox
                        style={{
                          backgroundColor: `${ColorCode}22`,
                          minHeight         : !isDesktop ? '85px' : '128px',
                          width          : !isDesktop ? '250px': '450px',
                          borderRadius   : '6px'
                        }}
                      >
                        <ScrollParent>
                            <ScrolledCard { ...props } /> 
                          </ScrollParent>                
                      </SwiperBox>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              { (isScrolled) && <Divider sx={{ height: '2px', background: '#D1D1D1', position: 'relative', width: '1000%', left: '-48px' }} />}
            </div>
          }
          </>
        )}
      </Box>
      </>
    )
}


const ScrolledCard = ({ status, floorplan, projeknr, address, townAndZipcode, pris, pricePf, i, isMobile, ColorCode }) => (
    <>
      <Box
        sx={{
          bgcolor   : ColorCode,
          color     : "#ffffff",
          fontWeight: 700,
              // py: '5px',
          height      : '8px',
          borderRadius: "6px 6px 0px 0px",
        }}
      >
      </Box>
      <div        style = {{ display: 'flex', alignItems: 'center' }}>
      <Box        sx    = {{ width:  isMobile ? "98px" : "173px", height: isMobile ? "58px" : "104px", padding: isMobile ? '9px 0 9px 8px' : "0 0 0 4px" }}>
      <ImgWrapper style = {{
              width       : "100%",
              height      : 'auto',
              borderRadius: '6px'
            }} img={floorplan} alt={"floorplan"}/>
        </Box>
        <Box sx={{ 
              // p: "10px 18px", 
          display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              position: "relative",
                  // lineHeight: 1.5,
              width        : isMobile ? "144px"  : "250px",
              minHheight       : isMobile ? "77px"   : "120px",
              padding      : isMobile ? "5px 8px": "18px 30px 24px 16px",
              boxSizing    : "border-box",
              display      : "flex",
              flexDirection: "column",
              gap          : "4px",
              lineHeight   : isMobile ? "20px"   : "15px"
            }}
          >
            <div style = {{ fontWeight: '600' }}>{projeknr}</div>
            <div style = {{ lineHeight: "20px",  
              // whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
            }}>
              <span style = {{ display: "inline-block",  width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{address}, </span>
              <span style = {{ display: "inline-block",  width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}> {townAndZipcode}</span>
            </div>
            {
              !isMobile && pris !== "0" && Boolean(pris) &&
              <Box>
              {pris} {pricePf.toLowerCase()}
              </Box>
            }
          </Box>
        </Box>
      </div>
    </>
  )

const NotScrolledCard = ({ status, floorplan, projeknr, address, townAndZipcode, pris, pricePf, i, isMobile, ColorCode }) => (
  <>
    <Box
        sx={{
        bgcolor   : ColorCode,
        color     : "#ffffff",
        fontWeight: 700,
        py        : '10px',
        px        : '24px',
            // height: '40px',
        lineHeight  : '20px',
        borderRadius: "6px 6px 0px 0px",
        display     : 'flex',
        alignItems  : 'center'

        }}
    >
        {status}
    </Box>
    <Box sx={{
            width  : "calc(100% - 1.1px)",
            height : isMobile ? "150px"  : "270px",
            padding: "0 1px"
        }}>
        <ImgWrapper style={{
            width : "100%",
            height: "auto"
        }} img={floorplan} alt={"floorplan"}/>
    </Box>
    <Box>
    <Box
    sx={{
        position: "relative",
        width   : "100%"
    }}
    >
    <div style = {{ height: isMobile ? '66px' : '77px', padding: isMobile ? '16px' :'8px 24px 0 24px', display: 'flex', flexDirection: 'column', gap: '4px', lineLeight: '20px' }}>
    <div style = {{ fontWeight: 600 }}>{projeknr}</div>
        <div>
        {address}, {townAndZipcode}
        </div>
       {
        !isMobile && pris !== "0" && Boolean(pris) &&
        <Box>
        {pris} {pricePf.toLowerCase()}
        </Box>
      }
    </div>

    <Divider/>

    <Stack style = {{ height: isMobile ? '41px' : '51px', padding: isMobile ? '0 40px' : '0 50px' }} direction = "row" justifyContent = "space-between">
        <ButtonShare
        unitId   = {i.UnitID}
        iconOnly = {isMobile}
        />
        <ButtonCompare
        unitId   = {i.UnitID}
        iconOnly = {isMobile}
        />
        <ButtonFavorite
        unitId   = {i.UnitID}
        iconOnly = {isMobile}
        />
    </Stack>
    </Box>
</Box>
</>
)

const SwiperWrapStyle = {
    width     : "100%",
    height    : "100%",
    overflow  : "initial",
    userSelect: "none",
};

const SwiperProps = {
    spaceBetween: 4,
    modules     : [FreeMode],
    freeMode    : {
      enabled       : true,
      sticky        : true,
      momentumBounce: false,
      momentum      : false,
    },
        // breakpoints: {
        //   580: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
        //   768: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
        //   1058: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
        //   1558: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
        // },
    slidesPerView: 'auto',
    style        : SwiperWrapStyle,
    grabCursor   : true,
        // centeredSlidesBounds: true,
        // centerInsufficientSlides: true,
   
    keyboard       : { enabled: true, onlyInViewport: false },
    resistance     : true,
    resistanceRatio: 0.0,
};

export default CompareCards;