import { Box, Button, IconButton } from "@mui/material";
import { IconBadge } from "components/Icons";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { getFocus, useAtom, useGet } from "state/jotai";
import { active_project, compares } from "state/store.global";
import { _project } from "state/store.projects";

function ButtonCompare({ unitId, iconOnly, label }) {
  const [comps, setComps] = useAtom(compares);
  const [count, setCount] = useState("");

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  useEffect(() => {
    if (comps.includes(unitId)) {
      setCount(comps.indexOf(unitId) + 1);
    } else {
      setCount("");
    }
  }, [unitId, comps]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (comps.includes(unitId)) {
        // remove from list.
        const list = comps.filter((i) => i !== unitId);
        setComps(list);
      } else {
        // add to list.
        const list = [...comps];
        if (3 === comps.length) {
          // max 3 items, remove an item.
          list.pop();
        }
        list.push(unitId);
        setComps(list);
      }
    },
    [comps, unitId]
  );

  return (
    <>
      {iconOnly ? (
        <IconButton onClick={handleClick}>
          <IconBadge icon="compare"  badge={count} isFaIcon={true} />
        </IconButton>
      ) : (
        <Button
          type="button"
          onClick={handleClick}
          color="inherit"
          startIcon={<IconBadge icon="compare" badge={count} isFaIcon={true} />}
          sx={{paddingTop:"0px",paddingBottom:"0px"}}
        >
          <Box component="span"   className="paragraph1-regular">
            {comps.includes(unitId) ? `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Tilføjet')[0]?.Label }` : `${buttonsLabel?.filter( btn => btn.UILabelProperty === 'Sammenlign')[0]?.Label}`}
          </Box>
        </Button>
      )}
    </>
  );
}

ButtonCompare.propTypes = {
  unitId: PropTypes.number.isRequired,
  iconOnly: PropTypes.bool,
};

export default ButtonCompare;
