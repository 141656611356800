import { useEffect, useState }                from 'react'
import { getFocus, useAtom, useGet }                 from 'state/jotai'
import { active_project, current_language, kioskMode, share_content }           from 'state/store.global'
import { vis_share }               from 'state/store.global'
import { Backdrop }                from 'styles/styles.backdrop'
import { EMAIL, STATICS }                 from 'api/api'
import { Icon }                    from 'assets/Icons_'
import { Button, Stack , ClickAwayListener , Tooltip }           from '@mui/material'
import { Container, Inner, Input } from './Share.Portal.style'
import { useTranslation }          from 'react-i18next'
// import { isMobile }                from 'react-device-detect'
import { SnackbarProvider, useSnackbar } from 'notistack';
import { axios_live } from 'utilities/axios.instances'

import { FacebookShareButton, FacebookMessengerShareButton } from 'react-share'
import { _project } from 'state/store.projects'
import { _bootstrap } from 'state/store.bootstrap'
import { useBreak } from 'hooks/useBreak'

import { useActiveUnit } from 'components/Unit'

const data = {
  Header1: 'Del bolig',
  Text1  : 'Forestiller du dig at denne bolig er det perfekte match til en person i dit netværk? Del den med det samme.',
  Header2: 'Indtast email',
}

export const SharePortal = ({}) => {
  const unit = useActiveUnit();
  const content             = useAtom(share_content)[0]
  // console.log(content,"content")
  const setShare            = useAtom(vis_share)[1]
  const { i18n:{language} } = useTranslation()
  const [email, setEmail]   = useState("")
  const [shareTooltip, setShareTooltip] = useState(false);
  const isMobile = useBreak("md_dn");
  let pattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/ ;
  // console.log(unit?.data?.ProjectID,"uniiii")
  const images = unit.getImages();
  const { enqueueSnackbar } = useSnackbar();
  const unitImage = images[2]?.url || "";
  
  const { pin, pid } = useGet(active_project)
  
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  // const ProjectID  = useGet(_bootstrap).projectids?.[0] || null
 
  const menus        = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];

  const KioskMode = useGet(kioskMode);

  const close   = { icon: 'close6', color: '#fff', wrap: { position: 'absolute', top: 20, right: 20 }, size: '20', click: () => setShare(false), }
  const homeUrl = window.location.origin;
  const unitUrl = `${homeUrl}/${language}/unit/${content?.unitId}`;
  const sitename  = useGet(_bootstrap).sitename;
  const [currentLanguage] = useAtom(current_language);

  const unit_size = content?.highlights?.filter((c) => c?.property === "Areal")
  let clearTooltip;  
  const inputChange    = (e) => { setEmail(e.target.value) }
  
  const handleDelBolig = () => {
    let success = 'success'
    let file = `${STATICS}email_logo.png`;
    let base64String;
    if(pattern.test( email )) {
   
      fetch(file)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Image not found or cannot be accessed.');
        }
        return response.blob();
      })
      .then((blob) => {
        const reader = new FileReader();
  
        reader.onload = function () {
          base64String = reader.result.split(',')[1];  // Extract the base64 part
    
          const payload =  {
            "type": "share-email",
            // "logo_base64": `data:image/png;base64,${base64String}`,
            "eido_color": clr?.primary,
            "eido_name": sitename,
          "front_page_url": 'https://www.mylo-living.dk/',
            "unit_address": content?.address,
            "unit_no": content?.unitId,
            "unit_rent": content?.rent,
            "unit_size": unit_size?.length ? `${unit_size[0]?.value} ${unit_size[0]?.postfix}` : "",
            "unit_url": unitUrl,
            "to_email": email,
          
            "ProjectID": unit?.data?.ProjectID
          }
          
          // let data = {
          //   "recipient_email": email,
          //   "eido_url"       : homeUrl,
          //   "cta_url"        : unitUrl,
          //   "external_url"   : menus[0]?.URL,
          //   "logo_base64"    : `data:image/png;base64,${base64String}`
          // }
  
          if(base64String) {
            axios_live.post(EMAIL(), payload)
            .then( res => {
              let variant = Object.hasOwnProperty.call(res.data, "recipient_email") ? 1 : 0;
              enqueueSnackbar( variant ? "Emailen er blevet sendt" : "E-mailen er ugyldig", { variant: variant ? 'success' : 'error' });
            })
            .catch( err => console.log(err) )
          }
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        alert(error.message);
      });
    }else {
      enqueueSnackbar( buttonsLabel?.filter(btn => btn?.UILabelProperty === 'PleaseEnterEmail')[0]?.Label, { variant: 'error' });
    }

    // const subject              = 'Bolig'
    // const message              = unitUrl
    //       window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
    // setShare(false)
  }
 
  
  const handleCopyToClipboard = () => { 
    navigator.clipboard.writeText(unitUrl) 
    clearTimeout(clearTooltip)
    setShareTooltip(true)
    clearTooltip = setTimeout(()=> setShareTooltip(false), 3000)
  }
  const handleTooltipClose = () => {
    clearTimeout(clearTooltip)
    setShareTooltip(false)
  }
  const handleSMS = () => {
    const message              = unitUrl
          window.location.href = `sms:?&body=${message}`
  }


  return (
    <div > 
    <form >
 
      <Backdrop onClick = {() => { setShare(false) }} />
      <Container isMobile={isMobile}>
      
        <img src = {unitImage} alt = ''  style={{ height   : "200px", objectFit: "cover",borderRadius:"6px"}} />
       
        <Icon {...close} />

        <Inner>
        
          <div className='h2' style={{paddingBottom:"16px"}}>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligOverskrift')[0]?.Label }</div>
          <div className='paragraph1-regular'>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligUnderoverskrift')[0]?.Label }</div>
          <div style = {{ height: 24 }} />
          <div className='h4' style={{paddingBottom:"8px"}}>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligemailOverskrift')[0]?.Label }</div>
          <Input pattern='/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/ '  type      = 'email' name="email"  placeholder = 'email@email.dk' onChange = {inputChange}></Input>
          <div    style     = {{ height: 24 }} />
          <Stack  direction = 'row' spacing      = '1px' justifyContent      = 'flex-start'>
         
          <Button  variant   = 'contained' size   = 'large' onClick= {handleDelBolig} style={{borderRadius:"6px", padding:"8px 24px 8px 24px"}} >{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBolig')[0]?.Label }</Button>
         
          </Stack>
          <div style = {{height:40}}/>
          { !KioskMode && <div style={{display:'flex',gap:20}}>
            {/* <FacebookShareButton          url  = {unitUrl}><Icon icon = 'facebook' color  = '#000' size = '30'/></FacebookShareButton>
            <FacebookMessengerShareButton url  = {unitUrl}><Icon icon = 'messenger' color = '#000' size = '30'/></FacebookMessengerShareButton> */}
            <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                  disablePortal: true,
                  sx: { 
                    'div': {
                      padding: '5px 10px',
                      backgroundColor:"black"
                    },
                    'span': {
                      color:"black"
                    }
                  }
                }}
                arrow 
                //disablePadding
                onClose={handleTooltipClose}
                open={shareTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Linket af kopieret til udklipsholder"
              >
                <Button sx={{ minWidth: "unset", padding: "7px 5px",backgroundColor:"#D4D4D4" ,borderRadius:"15px"}} onClick={handleCopyToClipboard}> <Icon isFaIcon={true} icon='link' color='#000'  size='30'/></Button>
              </Tooltip>
            </ClickAwayListener>
            {/* <Icon                         icon = 'link' color         = '#000' size       = '30' click  = {handleCopyToClipboard}/> */}
            {/* {isMobile && <Icon icon='sms' color='#000' size='30' click={handleSMS}/> } */}
          </div>}
          {/* <div style = {{height:24}}/> */}
          
        </Inner>
       
      </Container>
      
    
    </form>
    </div>
  )

}

