import { Card, Low, Status, Img, Separator, CTA } from 'components/List/Grid/Grid.Content.style'

import { Text } from 'components/List/Card/Card.Text'
import { get, getMoveInDate } from 'components/List/List.Utilities'

import { Icon } from 'assets/Icons_'
import { getFocus, useAtom, useGet } from 'state/jotai'
import { active_project, favorites } from 'state/store.global'
import { compares } from 'state/store.global'
import { FLOORPLAN } from 'api/api'
import { _project } from 'state/store.projects'
import { MoveDate } from '../Card/Card.Content.style'
import { useLocation } from 'react-router-dom'
import { ImgWrapper } from 'components/ImgContainer/ImgWrapper'
import { useBreak } from 'hooks/useBreak'
import CustomSnackbar from 'components/ToastMessage/CustomSnackbarToast'
import { useState } from 'react'
import moment from 'moment'

export const Content = ({ unit, thumbnail, color, snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose }) => {


  const here = useLocation().pathname;
  const atUnit = here.includes("unit");

  const likes = useAtom(favorites)[0]
  const setLikes = useAtom(favorites)[1]
  const comps = useAtom(compares)[0]
  const setComps = useAtom(compares)[1]
  const sID = unit.Data.UnitStatusID.Value
  const moveindate = getMoveInDate(unit, "Indflytningsdato");
  const todaydate = new Date().getTime();
  const today = Boolean(moveindate) ? new Date(moveindate) : false;

  // Specify Danish as the locale and customize options
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const danishFormattedDate = today ? today.toLocaleDateString('da-DK', options) : false

  const isDesktop = useBreak('md_up')

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const compare = { icon: 'compare3', color: clr?.compare, size: 28 }
  const compare_ = { icon: 'compare3', color: clr?.compare, size: 28 }
  const heartempty = { icon: 'heart_empty', color: clr?.heart_empty, size: 28 }
  const heartfull = { icon: 'heart_full', color: clr?.heart_full, size: 28 }

  const isLiked = likes.includes(unit.UnitID)
  const isComps = comps.includes(unit.UnitID)

  const toggleLike = (e) => {
    if (sID == 40) { return }
    e.stopPropagation()
    const add = [...likes, unit.UnitID]
    const rem = [...likes].filter(i => i !== unit.UnitID)
    setLikes(isLiked ? rem : add)
    handleSnackbarOpen(isLiked ? buttonsLabel?.filter(
      (btn) => btn.UILabelProperty === "ToastRemovedforFavorites"
    )[0]?.Label : buttonsLabel?.filter(
      (btn) => btn.UILabelProperty === "ToastAddedtoFavorites"
    )[0]?.Label);
  }

  const toggleComp = (e) => {
    if (sID == 40) { return }
    e.stopPropagation()
    const add = [...comps, unit.UnitID]
    const rem = [...comps].filter(i => i !== unit.UnitID)
    const max = comps.length == 3
    setComps(isComps ? rem : (max ? comps : add))
    handleSnackbarOpen(isComps ? buttonsLabel?.filter(
      (btn) => btn.UILabelProperty === "ToastRemovedforComparison"
    )[0]?.Label : buttonsLabel?.filter(
      (btn) => btn.UILabelProperty === "ToastAddedtoComparison"
    )[0]?.Label);
  }

  return (
    <>
      {/* <Img src={thumbnail} alt={FLOORPLAN} loading='lazy'/> */}
      <ImgWrapper style={{ height: isDesktop ? 'auto' : '215px' }} img={thumbnail} alt={FLOORPLAN} />
      {/* <div style={{height:5}}/> */}
      <Card>
        <Text atUnit={atUnit} unit={unit} cardType={"grid"} />
        <Separator color={color} />
        <Low atUnit={atUnit}>
          {unit.Data.UnitStatusID.Value !== 40 && <>
            <CTA className='paragraph1-regular' onClick={toggleComp}>
              <Icon isGrid={true} {...isComps ? compare_ : compare} isFaIcon={true} count={isComps ? comps.indexOf(unit.UnitID) + 1 : 0} />
              {isComps ? `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'Valgt')[0]?.Label}` : `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'Sammenlign')[0]?.Label}`}
            </CTA>
            <CTA onClick={toggleLike} className='paragraph1-regular'>
              <Icon  {...isLiked ? heartfull : heartempty} />
              {isLiked ? `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'DinFavorit')[0]?.Label}` : `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'GemBolig')[0]?.Label}`}
            </CTA>
          </>}
        </Low>
        <Status className='h4' color={get(unit, 'UnitColor')}>{get(unit, 'UnitStatusName')}</Status>
        <MoveDate className='paragraph2-light ' color={get(unit, 'UnitColor')}>
          {sID === 10 && moveindate ? (
           moment(danishFormattedDate, 'DD.MM.YYYY').isBefore(moment()) ? (
              <p style={{ display: 'inline', fontSize: 12, opacity: 0.7 }}>
                {`(${buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableTagTitle")[0]?.Label})`}
              </p>
            ) : (
              <p style={{ display: 'inline', fontSize: 12, opacity: 0.7 }}>
                ({buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableDatePrefix")[0]?.Label} {danishFormattedDate})
              </p>
            )
          ) : null}
        </MoveDate>
      </Card>
      {/* Snackbar */}
      <CustomSnackbar message={snackbarMessage} onClose={handleSnackbarClose} open={snackbarOpen} />
    </>
  )
}