import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import iconSet from "./iconset.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fontAwesomeIconSet from "./fontAwesomeIconSet.json"

function Icon({ isFaIcon, size, icon, ...props }) {

  return (
    <>
      {isFaIcon ? (
        <FontAwesomeIcon {...props} icon={fontAwesomeIconSet[icon]} size={size}/>
      ) : (
        <SvgIcon {...props}>
          <path d={iconSet[icon]} />
        </SvgIcon>
      )}
    </>
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default Icon;
