import styled, {css} from 'styled-components/macro'
import { BREAK } from 'constants'

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  height:45px;
  width:100%;
  position:relative;
  /* z-index:2; */
  padding:0 42px;
  ${ BREAK.md_dn( 'padding:0 16px')}
  /* border: 1px solid black; */
`
export const Section = styled.div`
  display: flex;
  flex: 1;
  ${({ left }) =>
    (() => {
      if (left)  return 'gap:7px'
    })()};
  line-height: 1;
 
  /* font-weight: 600; */
  // font-size: 1rem;
  /* border:1px solid black; */
  ${ BREAK.md_dn('font-size:14px')}
  ${({ left, mid, right }) =>
    (() => {
      if (left)  return 'justify-content:flex-start;align-items:center;'
      if (mid)   return 'justify-content:center    ;align-items:center;font-weight:400;'
      if (right) return 'justify-content:flex-end  ;align-items:center;'
    })()};
  `
export const Mode = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  /* background: ${({ mode, name }) => (mode === name ? '#e6e6e6' : '#fff')}; */
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  line-height: 1;
  cursor: pointer;
`