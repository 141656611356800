import { Box, CTA, Low, MoveDate, Separator, Status } from './Card.Content.style'
import { useBreak } from 'hooks/useBreak'
import { get, getMoveInDate } from 'components/List/List.Utilities'
import { Text } from './Card.Text'
import { Thumbnail } from './Card.Content.Thumbnail'
import { Icon } from 'assets/Icons_'
import { getFocus, useAtom, useGet } from 'state/jotai'
import { active_project, favorites } from 'state/store.global'
import { compares } from 'state/store.global'
import { _project } from 'state/store.projects'
import moment from 'moment'

export const Content = ({ unit, thumbnail, color, mode }) => {
  const likes = useAtom(favorites)[0]
  const setLikes = useAtom(favorites)[1]
  const comps = useAtom(compares)[0]
  const setComps = useAtom(compares)[1]
  const show = useBreak('card').toString()
  const sID = unit.Data.UnitStatusID.Value
  const moveindate = getMoveInDate(unit, "Indflytningsdato");

  const todaydate = new Date().getTime();

  const isMobile = useBreak('md_dn')

  const share      = { icon:'share'      , color:clr?.alternative_condition, size:isMobile?15:15 }
  const compare    = { icon:'compare3'   , color:clr?.alternative_condition, size:isMobile?25:25 }
  const compare_   = { icon:'compare3'   , color:clr?.alternative_condition    , size:isMobile?25:25 }
  const heartempty = { icon:'heart_empty', color:clr?.alternative_condition, size:isMobile?28:28 }
  const heartfull  = { icon:'heart_full' , color:'#863E30' , size:isMobile?28:28 }

  const isLiked = likes.includes(unit?.UnitID)
  const isComps = comps.includes(unit?.UnitID)

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const today = Boolean(moveindate) ? new Date(moveindate) : false;

  // Specify Danish as the locale and customize options
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const danishFormattedDate = today ? today.toLocaleDateString('da-DK', options) : false
  
  const toggleLike = (e) => {
    if (sID == 40) { return }
    e.stopPropagation()
    const add = [...likes, unit.UnitID]
    const rem = [...likes].filter(i => i !== unit.UnitID)
    setLikes(isLiked ? rem : add)
    log({
      action: isLiked
        ? 'removed_apartment_from_favorites'
        : 'added_apartment_to_favorites'
      , unitId
    })
  }

  const toggleComp = (e) => {
    if (sID == 40) { return }
    e.stopPropagation()
    const add = [...comps, unit.UnitID]
    const rem = [...comps].filter(i => i !== unit.UnitID)
    const max = comps.length == 3
    setComps(isComps ? rem : (max ? comps : add))
  }

  return (
    <>
      {show == 'true' && <Thumbnail thumbnail={thumbnail} />}
      <Box>
        <Text mode={mode} unit={unit} />
        {/* <div style={{height:12}}/> */}
        <Separator type={show} color={color} />
        {/* <div style={{height:8}}/> */}
        <Low type={show}>
          <CTA className='paragraph1-regular' onClick={toggleComp}>
            <Icon {... isComps ? compare_ : compare } isFaIcon={true} count={ isComps ? comps.indexOf(unit.UnitID)+1 : 0}/>
            {isComps ? `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'Valgt')[0]?.Label}` : `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'Sammenlign')[0]?.Label}`}
          </CTA>
          <CTA onClick={toggleLike} className='paragraph1-regular'>
          {
            show == 'true' ? <>
              <Icon {... isLiked ? heartfull : heartempty}/>{ isLiked ? `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'DinFavorit')[0]?.Label }` : `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'GemBolig')[0]?.Label }` }
            </> : <>
              <Icon {...isLiked ? heartfull : heartempty}/>{ isLiked ? `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'DinFavorit')[0]?.Label }` : `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'GemBolig')[0]?.Label }` }
            </>
          }
          </CTA>
        </Low>
        <Status mode={mode} className='h4' color={get(unit, 'UnitColor')}>{get(unit, 'UnitStatusName')}</Status>
        <MoveDate mode={mode} className='paragraph2-light ' color={get(unit, 'UnitColor')}>
          {sID === 10 && moveindate ? (
           moment(danishFormattedDate, 'DD.MM.YYYY').isBefore(moment()) ? (
              <p style={{ display: 'inline', fontSize: 12, opacity: 0.7 }}>
                {`(${buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableTagTitle")[0]?.Label})`}
              </p>
            ) : (
              <p style={{ display: 'inline', fontSize: 12, opacity: 0.7 }}>
                ({buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableDatePrefix")[0]?.Label} {danishFormattedDate})
              </p>
            )
          ) : null}
        </MoveDate>
      </Box>
    </>
  )
}