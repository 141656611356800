const palette = {
  primary: {
    main: "#F47979",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#C7AD99",
    contrastText: "#000000",
  },
  text: {
    primary: "#000000",
    
  },
  success: {
    main: "#0E912C",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
};

export default palette;
